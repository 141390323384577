import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/Layout';
import Menu from '../components/Menu';

import * as styles from './404.module.scss';
import { withI18n } from '../utils/hocs';
import PageSEO from '../components/PageSEO';
import { useLocalization } from '../utils/hooks';
import { MenuItem, SubmenuItem } from '../graphql-fragments/Menu';

interface LocalizedQueryData {
  sanityWebsite: {
    headerNavigationMenuReference: {
      title: string;
      items: Array<MenuItem | SubmenuItem>;
    };
  };
}
const Error404Page = (): React.ReactElement => {
  const data = useStaticQuery(
    graphql`
      {
        sanityWebsite {
          headerNavigationMenuReference {
            title
            items {
              ...Menu
            }
          }
        }
      }
    `,
  );

  const { t, localizedData } = useLocalization<LocalizedQueryData>(data);

  const headerMenu = localizedData.sanityWebsite.headerNavigationMenuReference || {};

  const entries = headerMenu.items.map(item => {
    return item.__typename === 'SanitySubmenuItem'
      ? {
          url: '/' + item.entries[0].page.slug.current,
          title: item.title,
        }
      : {
          url: '/' + item.page.slug.current,
          title: item.title || item.page.title,
        };
  });

  return (
    <Layout transparentHeader={false}>
      <PageSEO defaultTitle={t('404_page.title', 'Page not found')} />
      <div className={styles.container}>
        <div className={styles.sectionTitleContainer}>
          <div className={styles.border}></div>
          <h2> {t('404_page.title', 'Page not found')}</h2>
          <div className={styles.border}></div>
        </div>

        <span className="titleParagraph">
          {t(
            '404_page.error_text',
            'Error 404 - This address does not correspond to any page on our website.',
          )}
        </span>
        <span className="titleParagraph">
          {t(
            '404_page.help_text',
            'You may find what you are looking for in one of the sections of the website below.',
          )}
        </span>
      </div>
      <div className={styles.menuWrapper}>
        <Menu pageContent entries={entries}></Menu>
      </div>
    </Layout>
  );
};

export default withI18n(Error404Page);
